import React, {Component} from 'react';
import PageTitle from './PageTitle.js';

class WebCourses extends Component {

  render () {
    return (
      <div>
        <PageTitle title="Webbutbildningar" />
      </div>
    );
  }
}
export default WebCourses;
