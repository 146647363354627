import React, {Component} from 'react';
import PageTitle from './PageTitle.js';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { BeatLoader } from 'react-spinners';
import Popup from 'react-popup';
import './Popup.css';

//confirm window
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const firebase = require('../Firebase.js');

// hantera användare

class HandleUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      addUser:false,
      text: "Laddar...",
    }
  }

  componentDidMount() {
    const db = firebase.firestore();
    //const settings = {/* your settings... */ timestampsInSnapshots: true};
    //db.settings(settings);*/
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection('users').onSnapshot((snapshot) => {
      const users = firebase.getArray(snapshot);
      this.setState({ data: users });
        /*snapshot.forEach((doc) => {
          console.log(doc.id, "=>", doc.data());
          this.setState({ data: firebase.getArray(snapshot), key: doc.id });
        });*/
    });
    setTimeout(function(){
      if (!this.state.data) {
        this.setState({
          text: "Hittade inga användare."
        });
      }
    }.bind(this), 5000);
  }

  addUser = e => {
    this.setState({addUser: e})
  }

  render() {
    let content;
    let secondary;
    if (this.state.addUser) {
      content = <AddUser abortAdd={this.addUser}/>;
    }else {
      if (this.state.data) {
        secondary =  (
          <div style={{overflow:"hidden"}}>
            <UserList data={this.state.data}/>
          </div>
        );
      }else {
        secondary = <h4>Hittade inga användare</h4>;
      }
      content = (
      <div>
        <PageTitle title="Hantera användare" />
        <button className="smallGreenButton" onClick={() => this.setState({addUser:true})}>Lägg till ny användare</button>
        <div style={{height:'100%'}}>
          {secondary}
        </div>
      </div>
    )
    }

    return content;
  }
}

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      fullname: "",
      password: "",
      role: "",
      company: true,
      phonenumber: "",
      readonly: false,
      spinner: false
    }
  }
  updateInput = e => {
  this.setState({
    [e.target.name]: e.target.value
    });
  }
  addUser = e => {

    //SKAPAR EN ANVÄNDARE MED FIREBASE OCH SEN ETT DOKUMENT I COLLECTION "USERS" DÄR BÅDE ANVNAMN OCH FULLNAME LÄGGS TILL.
    //OM DU VILL LÄGGA TILL MER INFO OM PERSONEN SÅ LÄGG TILL DET UNDER .set({}) NEDAN - Glöm inte att sätta state till null sen efter
    e.preventDefault();
    if (this.state.email.length >0 && this.state.fullname.length > 0 && this.state.password.length > 0 && this.state.role.length > 0 && this.state.phonenumber.length > 0) {
      this.setState({spinner: true});
      const createUser = firebase.functions().httpsCallable('createUser');
      createUser({email:this.state.email, password:this.state.password}).then((result) => {
        const data = result.data;
        if (data.completed) {
          const db = firebase.firestore();
          db.settings({
            timestampsInSnapshots: true
          });
          let company;
          if (this.state.company) {
            company = "Ja";
          }else {
            company = "Nej";
          }

          db.collection("users").doc(data.message).set({
            fullname: this.state.fullname,
            email: this.state.email,
            role: this.state.role,
            company: company,
            phonenumber:this.state.phonenumber
          }).then(() => {
            this.setState({spinner: false})
            Popup.alert("Användaren skapades");
            this.setState({
              fullname: "",
              email: "",
              password:"",
              role: "",
              company: true,
              phonenumber:""
            });
          });
        }else {
          const errMessage = data.message;
          window.alert(errMessage);
        }
      });

      /*firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(function(response){


      }.bind(this)).catch(function(error) {
        // Handle Errors here.
        //const errorCode = error.code;
        const errorMessage = error.message;
        window.alert(errorMessage);
          // ...
      });*/

    }else {
      window.alert("Vänligen fyll i alla fält");
    }


  }

  render () {

    let yesno;
    let checked;
    if (this.state.company) {
      checked = true;
      yesno = "Ja";
    }else {
      checked = false;
      yesno = "Nej";
    }
    let spinner;
    if (this.state.spinner) {
      spinner = (
        <div style={{width:"100vw",height:"100vh", position:'fixed', background:'rgba(48, 70, 130, 0.59)',zIndex:500, left:0, top:0}}>
        <BeatLoader
          sizeUnit={"px"}
          size={40}
          className="spinner-full"
          color={'white'}
          loading={true}
        />
      </div>)
    }
    return (
      <div>
        {spinner}
        <Popup />
        <PageTitle title="Lägg till användare" />
        <div className="fullWidth">
          <ul className="horizontalList">
            <li>
              <button className="closeButton" onClick={() => this.props.abortAdd(false)}>TILLBAKA</button>
            </li>
          </ul>
        </div>
        <div className="section">
          <div className="fullWidth">
            <form className="inputForm" onSubmit={this.addUser}>
              <table readOnly>
                <tbody>
                  <tr>
                    <td className="label">Fullständigt namn:</td>
                    <td>
                      <input
                        type="text"
                        name="fullname"
                        placeholder=""
                        onChange={this.updateInput}
                        value={this.state.fullname}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Email:</td>
                    <td>
                      <input
                        type="text"
                        name="email"
                        placeholder=""
                        onChange={this.updateInput}
                        value={this.state.email}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Lösenord:</td>
                    <td>
                      <input
                        type="text"
                        name="password"
                        placeholder=""
                        onChange={this.updateInput}
                        value={this.state.password}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Befattning:</td>
                    <td>
                      <input
                        type="text"
                        name="role"
                        placeholder=""
                        onChange={this.updateInput}
                        value={this.state.role}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Anställd:</td>
                    <td>
                      <input checked={checked} name="company" type="checkbox" onClick={(e) => this.setState({company:e.target.checked})} value={this.state.company} />
                      {yesno}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">Telefonnummer:</td>
                    <td>
                      <input
                        type="text"
                        name="phonenumber"
                        placeholder=""
                        onChange={this.updateInput}
                        value={this.state.phonenumber}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <button className="bigGreenButton" type="submit">
                SKAPA ANVÄNDARE
              </button>
            </form>
            <p><i>Ett mail kommer att skickas till användaren<br /> med dennes inloggningsuppgifter</i></p>
          </div>
        </div>

      </div>
    );
  }
}

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  deleteUser = e => {
    const db = firebase.firestore();
    //const settings = {/* your settings... */ timestampsInSnapshots: true};
    //db.settings(settings);*/
    db.settings({
      timestampsInSnapshots: true
    });
    function confDelete() {
      db.collection("users").doc(e).delete();
      console.log("Successfully deleted user");
    }
    confirmAlert({
        title: 'Användaren kommer raderas',
        message:'Vill du fortsätta?',
        buttons: [
          {
            label: 'Ja',
            onClick:() => confDelete()
          },
          {
            label: 'Nej',
            onClick:null
          }
        ]
      });
  }
  render() {
    const columns = [{
        Header: 'Namn',
        accessor: 'fullname' // String-based value accessors!
      },
      {
        Header: 'Email',
        accessor: 'email', // Custom cell components!
      },
      {
        Header: 'Anställd',
        accessor: 'company',
      }
    ];

    return (<ReactTable
      data={this.props.data}
      filterable
      defaultFilterMethod={(filter, row) =>
      String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
      columns={columns}
      className="-striped -highlight"
      SubComponent={row => {
        const user = row.original;
        const uid = user.id;
        const changeEmployed = (id, company) => {
          let newComp;
          let message;
          if (company === "Ja") {
            newComp = "Nej";
            message = "ej anställd"
          }else {
            newComp = "Ja";
            message = "anställd"
          }
          confirmAlert({
              title: 'Ändra ' + user.fullname + " till " + message,
              message:'Vill du fortsätta?',
              buttons: [
                {
                  label: 'Ja',
                  onClick:() => confirm()
                },
                {
                  label: 'Nej',
                  onClick:null
                }
              ]
            });
          function confirm () {
            const db = firebase.firestore();
            db.settings({timestampsInSnapshots: true});
            db.collection("users").doc(id).update({
              company: newComp
            })
          }

        }
        let company = user.company;
        console.log("COMPANY: " + company);
        let checked;
        if (company === "Ja") {
          checked = true;
        }else {
          checked = false;
        }
        return (
          <div style={{padding:10, width:"90%"}}>
            <div style={{display: "inline-block", verticalAlign: "top", width: "50%"}}>
              <p><b>Befattning:</b> {user.role}</p>
              <p><b>Telefonnumer:</b> {user.phonenumber}</p>
            </div>
            <div style={{display: "inline-block", verticalAlign: "top", width: "50%"}}>
              <p style={{textAlign: "right"}}>Anställd:
                <input checked={checked} type="checkbox" value={user.company} onChange={() => changeEmployed(user.id, user.company)}/>
              </p>
              <button className="closeButton" style={{float:'right'}} onClick={() => this.deleteUser(uid)}>Ta bort</button>
            </div>
          </div>
        )
      }}
    />)
  }
}
export default HandleUsers;



//tips
/**

{
 id: 'friendName', // Required because our accessor is not a string
 Header: 'Friend Name',
 accessor: d => d.friend.name // Custom value accessors!
}, {
 Header: props => <span>Friend Age</span>, // Custom header components!
 accessor: 'friend.age'
}


{
  Header: '',
  Cell: props => <div>
    <button className="smallBlueButton" onClick={() => window.alert(JSON.stringify(props.original.id))}>Redigera</button><button className="closeButton">Ta bort</button>
  </div> // Custom cell components!
},
*/
