import React, {Component} from 'react';
import PageTitle from './PageTitle.js';
import NewCourse from "../containers/NewCourse.js";
import ReactTable from "react-table";
import 'react-table/react-table.css'
//import Popup from 'react-popup';
//import './Popup.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const firebase = require('../Firebase.js');

class OpenCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      data:null
    }
  }
  componentDidMount () {
    if (this.props.data) {
      this.setState({
        data: this.props.data
      })
    }
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection("courses").doc(this.props.openId)
      .onSnapshot ((doc) => {
        //console.log(JSON.stringify(doc.data().anmalda[0].fullname));
        const anmaldaArray = firebase.arrayFromObject(doc.data().anmalda);
        const pendingArray = firebase.arrayFromObject(doc.data().pending);
        this.setState({data: doc.data(), anmalda: anmaldaArray, pending: pendingArray});
    });
    if (firebase.user) {
      db.collection('courses').doc(this.props.openId).collection('waitList').onSnapshot((doc) => {
        this.setState({waitList: firebase.getArray(doc)});
      });
    }
  }

  abort = e => {
    if (this.state.editing) {
      const confAbort = () => {
        this.setState({
          editing: false
        })
      }
      confirmAlert({
        title: 'Vill du avbryta redigeringen av kursen?',
        buttons: [
          {label: 'Ja', onClick:() => confAbort()},
          {label: 'Nej', onClick:null}
        ]
      });
    }else {
      this.props.toggleOpen(0);
    }
  }

  updateInput = e => {
    if (this.state.editing) {

    }else {
      this.setState({editing: true})
    }
  }

  skickaFaktura = e => {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection("courses").doc(this.props.openId).update({
      ['pending.'+e+'.fakturaSent']: true
    })
  }
  setPaid = e => {
    confirmAlert({
      title: "Markera som betald?",
      message: "Du markerar just nu anmälan som betald. Ett mail kommer skickas ut till personen där anmälan bekräftas.",
      buttons: [
        {label: "Fortsätt", onClick: () => confirm()},
        {label: "Avbryt", onClick: null}
      ]
    })
    const confirm = () => {
      const confirmBooking = firebase.functions().httpsCallable("confirmBooking");
      confirmBooking({user: e, course: this.state.data});
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      const docRef = db.collection("courses").doc(this.props.openId);
      docRef.update({
        ['anmalda.'+e.id]: e
      });
      docRef.update({
        ['pending.'+e.id]:firebase.firestore.FieldValue.delete()
      });
    }

  }
  setPend = e => {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const docRef = db.collection("courses").doc(this.props.openId);
    docRef.update({
      ['pending.'+e.id]: e
    });
    docRef.update({
      ['anmalda.'+e.id]:firebase.firestore.FieldValue.delete()
    });
  }

  avanmal = (user, betalat) => {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const docRef = db.collection("courses").doc(this.props.openId);
    const userRef = db.collection("users").doc(user.id).collection("anmaldTill").doc(this.props.openId);
    if (betalat) {
      const confAbort = () => {
        if (this.state.data.type === "Fysisk utbildning") {
          docRef.update({
            ["anmalda."+user.id]:firebase.firestore.FieldValue.delete(),
            "platser":[this.state.data.platser + 1]
          });
        }else {
          docRef.update({
            ["anmalda."+user.id]:firebase.firestore.FieldValue.delete()
          });
        }
        userRef.delete();
      }
      confirmAlert({
        title: 'Avanmäl',
        message:  `Är du säker på att du vill avanmäla användaren ${user.fullname}? Se till att en återbetalning har gjorts. Med kortbetalning via Stripe gör du detta via deras dashboard.`,
        buttons: [
          {label: 'Ja, återbetalning har gjorts', onClick:() => confAbort()},
          {label: 'Avbryt', onClick:null}
        ]
      });
    }else {
      const confAbort = () => {
        if (this.state.data.type === "Fysisk utbildning") {
          docRef.update({
            ["pending."+user.id]:firebase.firestore.FieldValue.delete(),
            "platser":[this.state.data.platser + 1]
          });
        }else {
          docRef.update({
            ["pending."+user.id]:firebase.firestore.FieldValue.delete()
          });
        }

        userRef.delete();
      }
      confirmAlert({
        title: 'Avanmäl',
        message:  `Är du säker på att du vill avanmäla användaren ${user.fullname}?`,
        buttons: [
          {label: 'Ja', onClick:() => confAbort()},
          {label: 'Nej', onClick:null}
        ]
      });
    }


  }
  saveUserInfo = (text, type, user) => {
    if (type === "betald") {
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      const ref = db.collection('courses').doc(this.props.openId);
      ref.update({
        ["anmalda."+user.id+".note"]:text
      });
    }
    else {
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      const ref = db.collection('courses').doc(this.props.openId);
      ref.update({
        ["pending."+user.id+".note"]:text
      });
    }

  }
  render () {
    const data = this.state.data ? this.state.data : {type:"loading"};
    const userData = this.state.anmalda ? this.state.anmalda : [];
    const userPend = this.state.pending ? this.state.pending : [];
    const totalAnmalda = userData.length + userPend.length;

    let platserKvar;
    if (data.type === "Fysisk utbildning") {
      platserKvar = <li><h3>Platser kvar: {data.platser}</h3></li>
    }

    let courseContent;
    let editButton = <button className="smallBlueButton" onClick={this.updateInput}>Redigera</button>
    let abortText = "Tillbaka";
    if (this.state.editing) {
      abortText = "Avbryt";
      editButton = null;
    }

    if (data && userData && userPend) {
      if (this.state.editing) {
        courseContent = (
          <div>
            <div className="section clearfix">
              <div className="fullWidth">
                <NewCourse stopEditing={()=> this.setState({editing:false})} prefill={{totalAnmalda: totalAnmalda, title:data.title, date:data.date, starts: data.starts, ends: data.ends, desc:data.desc, price:data.price, price_employee:data.price_employee, web:data.web, visitaddress:data.visitaddress, webinar:data.webinar, postalcode:data.postalcode, ort:data.ort, platser:data.platser, type:data.type, courseId:this.props.openId}}/>
              </div>
            </div>
          </div>
        );
      }else {
        courseContent = (
          <div>
            <div className="section clearfix">
              <div className="fullWidth">
                <ul className="horizontalList">
                  <li><h3>Typ: {data.type}</h3></li>
                  <li><h3>Datum: {data.date}</h3></li>
                  <li><h3>Klockan: {data.starts} - {data.ends}</h3></li>
                  {platserKvar}
                  <li><h3>Antal anmälda: {totalAnmalda}</h3></li>
                </ul>
              </div>
            </div>
            <div className="section clearfix">
              <div className="fullWidth">
                <h3>Innehåll</h3>
                <pre>{data.desc}</pre>
              </div>
            </div>
          </div>
        );
      }

      const columns = [
        {Header: "Namn", accessor: "fullname"},
        {Header: "Betalningsmetod", accessor: "paymentType"},
        {Header: "Datum för anmälan", accessor: "timestamp",
          Cell: row => (
            <span>{firebase.timeConvert(row.value) ? firebase.timeConvert(row.value) : ""}</span>
          )
        },
        {Header: "Allergier", accessor: "allergies",
          Cell: row => (
            <span>{row.value ? row.value : null}</span>
          )
        }
      ];

      const columnsWaitList = [
        {Header: "Namn", accessor: "userInfo.fullname"},
        {Header: "Datum för anmälan", accessor: "timestamp",
          Cell: row => (
            <span>{firebase.timeConvert(row.value) ? firebase.timeConvert(row.value) : ""}</span>
          )
        }
      ];

      let pageSizeEjBet = 10;
      let pageSizeBet = 10;
      if (userPend.length < 10) {
        pageSizeEjBet = 5;
      }
      if (userData.length < 10) {
        pageSizeBet = 5;
      }
      if(this.state.waitList) {
        /**console.log("WAITLIST INFO: " + JSON.stringify(this.state.waitList.userInfo.email));
        console.log("WAITLIST TIME: " + JSON.stringify(this.state.waitList.timestamp));**/
        console.log("WAITLIST: " + JSON.stringify(this.state.waitList));
      }


      return (
        <div>
          <PageTitle title={data.title} />
          <div className="fullWidth">
            <ul className="horizontalList">
              <li><button className="closeButton" onClick={this.abort}>{abortText}</button></li>
              <li>{editButton}</li>
            </ul>
          </div>
          {courseContent}
          {data.type === "Webbutbildning" && <UploadVid courseId={this.props.openId} videos={data.videos ? data.videos : null}/>}
          <div className="section clearfix">
            <h2>Anmälda, ej betalda</h2>
            <ReactTable
              data={this.state.pending}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
              columns={columns}
              defaultPageSize={pageSizeBet}
              className="-striped -highlight"
              SubComponent={row => {
                const user = row.original;
                let fakturaAdress;
                let fakturaButton;
                if (user.paymentType === "faktura") {
                  fakturaAdress = (<div><p><b>Faktureringsadress:</b></p><p>{user.fakturaDetails.vag}, {user.fakturaDetails.zip} {user.fakturaDetails.ort}</p></div>);
                  if (!user.fakturaSent) {
                    fakturaButton = (<button className="smallBlueButton" style={{float:'right'}} onClick={() => this.skickaFaktura(user.id)}>Fakturan har skickats</button>);
                  } else {
                    fakturaButton = <p>Fakturan har skickats</p>
                  }
                }
                let phone;
                if (user.phonenumber) {
                  phone = (<p><b>Telefon: </b>{user.phonenumber}</p>);
                }
                return (
                  <div style={{padding:10, width:"90%"}}>
                    <p><b>E-post: </b>{user.email}</p>
                    {phone}
                    {fakturaAdress}
                    <button className="closeButton" style={{float:'right'}} onClick={() => this.avanmal(user, false)}>Ta bort</button>
                    <button className="smallBlueButton" style={{float:'right'}} onClick={() => this.setPaid(user)}>Markera som betald</button>
                    <textarea defaultValue={user.note} id={"pending_anteckning_"+user.id} placeholder="Anteckning" style={{minWidth:'40%'}} />
                    <button onClick={() => this.saveUserInfo(document.getElementById("pending_anteckning_"+user.id).value, "pending", user)}>Spara</button>
                    {fakturaButton}
                  </div>
                )
              }}
            />
          </div>
          <div className="section clearfix">
            <h2>Anmälda, betalda</h2>
            <ReactTable
              data={this.state.anmalda}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
              columns={columns}
              defaultPageSize={pageSizeEjBet}
              className="-striped -highlight"
              SubComponent={row => {
                const user = row.original;
                let phone;
                let markAsNotPaid;
                if (user.paymentType !== "stripe") {
                  markAsNotPaid = <button className="smallBlueButton" style={{float:'right'}} onClick={() => this.setPend(user)}>Markera som ej betald</button>;
                }
                if (user.phonenumber) {
                  phone = (<p><b>Telefon: </b>{user.phonenumber}</p>);
                }
                return (
                  <div style={{padding:10, width:"90%"}}>
                    <p><b>E-post: </b>{user.email}</p>
                    {phone}
                    <textarea defaultValue={user.note} id={"betald_anteckning_"+user.id} placeholder="Anteckning" style={{minWidth:'40%'}} />
                    <button onClick={() => this.saveUserInfo(document.getElementById("betald_anteckning_"+user.id).value, "betald", user)}>Spara</button>
                    <button className="closeButton" style={{float:'right'}} onClick={() => this.avanmal(user, true)}>Avanmäl</button>
                    {markAsNotPaid}
                </div>
              )
            }}
          />
          </div>
          <div className="section clearfix">
            <h2>Väntlistade</h2>
            <ReactTable
              data={this.state.waitList}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
              columns={columnsWaitList}
              defaultPageSize={5}
              className="-striped -highlight"
              SubComponent={row => {
                const user = row.original;
                return (
                  <div style={{padding:10, width:"90%"}}>
                    <p><b>E-post: </b>{user.userInfo.email}</p>
                    <button className="closeButton" style={{float:'right'}}>Avanmäl</button>
                </div>
                )
              }}
            />
          </div>
        </div>
      );
    }else {
      return (
        <div>
          <h4>Laddar...</h4>
          <button className="closeButton" onClick={this.abort}>{abortText}</button>
        </div>
      );
    }

  }
}

class UploadVid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      videoUrl:'',
      titel:'Video 1',
      loading:false,
      videos:[],
      video:null
    }
  }
  componentDidMount() {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    /**db.collection("courses").doc(this.props.courseId).collection("videos").onSnapshot((snapshot) => {
      const videos = firebase.getArray(snapshot);
      this.setState({videos:videos});
    });**/
    db.collection("courses").doc(this.props.courseId).onSnapshot((snapshot) => {
      const doc = snapshot.data();
      const video = doc.video;
      this.setState({video:video});
    });
  }

  uploadFile(file) {
    this.setState({loading:true})
    console.log(file);
    const storage = firebase.storage();
    const ref = storage.ref();
    const videoPath = ref.child('videos/'+this.props.courseId+'/'+file.name);
    videoPath.put(file).then(function(snapshot) {
      snapshot.ref.getDownloadURL().then((url) => {
        this.setState({videoUrl:url, loading:false});

      });
    }.bind(this));
  }

  addVideo() {
    if (this.state.videoUrl.length > 0 && this.state.titel.length>0) {
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      /**db.collection("courses").doc(this.props.courseId).collection("videos").add({
        titel:this.state.titel,
        videoUrl:this.state.videoUrl
      }).catch((error) => {
        window.alert(error.message);
      })**/
      db.collection("courses").doc(this.props.courseId).update({
        video:{titel:this.state.titel,videoUrl:this.state.videoUrl}
      });
    }
  }

  removeVideo() {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection("courses").doc(this.props.courseId).update({
      video:firebase.firestore.FieldValue.delete()
    });
  }

  render() {

    const video = this.state.video;

    return(
      <div>

        <div className="section clearfix">
          {(!this.state.loading && <div className="fullWidth">
            <h4>Utbildningsmaterial (Videos)</h4>
            <p>Här kan du lägga till material till din utbildning exempelvis videon efter att webinar är avslutat. Användare kan när som helst se detta om de köpt kursen och betalningen är godkänd.</p>
            Titel: <input size="20" type="text" placeholder="Video nummer 1" onChange={(e) => this.setState({titel:e.target.value})} value={this.state.titel}/><br/>
            URL: <input size="50" type="url" placeholder="https://google.com" onChange={(e) => this.setState({videoUrl:e.target.value})} value={this.state.videoUrl}/>&#160;
            eller&#160;<input type="file" accept="video/*" onChange={(e) => this.uploadFile(e.target.files[0])}/>&#160;<br/>
            {this.state.videoUrl.length> 0 && <video width="320" height="240" controls src={this.state.videoUrl}>
            </video>}
            <br/><button className="smallBlueButton" onClick={()=>this.addVideo()}>Lägg till</button>
          </div>) || <h1>Laddar upp video ..</h1>}
        </div>
        {this.state.video && <div className="section clearfix">
          <div className="fullwidth">
            <h4>Uppladdade videos:</h4>
            {/**this.state.videos.map(video => {
              return (
                <div>
                  <p><b>Titel: </b>{video.titel}</p>
                  <video width="320" height="240" controls src={video.videoUrl}>
                  </video>
                </div>
              );
            })**/}
            <div>
              <p><b>Titel: </b>{video.titel}</p>
              <video width="320" height="240" controls src={video.videoUrl}>
              </video>
            </div>
            <button onClick={() => this.removeVideo()} className="closeButton">Ta bort</button>
          </div>
        </div>}
      </div>
    );
  }
}

export default OpenCourse;
