import React from 'react';
import './Buttons.css';
//var firebase = require("../Firebase.js");


class LogOutButton extends React.Component {

  /*logOut() {
    firebase.auth().signOut().then(function() {
  // Sign-out successful.
    }, function(error) {
  // An error happened.
    });
  }*/

  render() {
    const loggedIn = this.props.loggedIn;
    if (loggedIn) {
      return (
        <button onClick={(e) => this.props.change(false)} type='button' id="LogOutButton" className="bigGreenButton" href="#">
          Logga ut
        </button>
      );
    }else {
      return (
        <button onClick={(e) => this.props.change(true)} type='button' id="LogOutButton" className="bigGreenButton" href="#">
          Logga in
        </button>
      );
    }

  }
}

export default LogOutButton;
