import React, {Component} from 'react';

import './form.css';

import TextareaAutosize from 'react-autosize-textarea';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const firebase = require('../Firebase.js');


class NewCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      date: 0,
      starts: 0,
      ends: 0,
      desc: "",
      price: 0,
      price_employee: 0,
      web:false,
      visitaddress:"",
      webinar:"",
      postalcode: "",
      ort: "",
      platser: 0,
      type: "Fysisk utbildning",
      prefill: false,
      courseId: null
    }
  }

  componentDidMount() {
    const pre = this.props.prefill;
    //console.log("PRE WEB: "+ pre.web)
    if (pre) {
      this.setState({
        title: pre.title,
        date: pre.date,
        starts: pre.starts,
        ends: pre.ends,
        desc: pre.desc,
        price: pre.price,
        price_employee: pre.price_employee,
        type: pre.type,
        prefill: true,
        courseId: pre.courseId
      })
      if (pre.type === "Webbutbildning") {
        this.setState({
          web: true,
          webinar: pre.webinar
        })
      }else {
        this.setState({
          visitaddress: pre.visitaddress.address,
          webinar: pre.webinar,
          postalcode: pre.visitaddress.postalcode,
          ort: pre.visitaddress.ort,
          platser: pre.platser,
        });
        }
      }

  }

  updateCourse = () => {
    if (this.state.prefill && this.state.courseId) {
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      db.collection("courses").doc(this.state.courseId).update({
        title: this.capitalize(this.state.title),
        date: this.state.date,
        starts: this.state.starts,
        ends: this.state.ends,
        desc: this.capitalize(this.state.desc),
        price: Number(this.state.price),
        price_employee: Number(this.state.price_employee),
        webinar: this.state.webinar,
        visitaddress: {address: this.state.visitaddress, postalcode: this.state.postalcode, ort:this.state.ort},
        platser: Number(this.state.platser),
        type: this.capitalize(this.state.type)
      }).then(this.props.stopEditing());
    }
  }

  updateInput = e => {
    const x = document.getElementsByName(e.target.name)[0];
    x.style.boxShadow = null;
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  updateType = e => {
    //pga delay
    if (this.state.web) {
      this.setState({type: "Fysisk utbildning"});
    }else {
      this.setState({type: "Webbutbildning"});
    }
  }

  capitalize = (e) => {
    if (e.length > 0) {
      const firstLetter = e[0].toUpperCase();
      const restOfWord = e.substring(1);
      return firstLetter + restOfWord;
    }else {
      return e;
    }
  }

  checkInput = e => {
    e.preventDefault();
    const elements = document.getElementById("courseForm").getElementsByTagName("input");
    console.log(elements);
    let missing = [];
    for (var i = 0, element; element = elements[i++];) {
      console.log("VAL: " + element.value)
      if (element.value === "") {
        console.log("it's an empty textfield " + element.name)
        element.style.boxShadow = "0 0 10px 2px rgba(255, 46, 0, 0.6)";
        missing.push(element.name);
      }
    }
    console.log("MIISING: " + missing)
    if (missing.length === 0) {
      console.log("REDO NU");
      if (Number(this.state.price) === 0) {
        confirmAlert({
          title: "Utbildningen saknar pris",
          message: "Vill du fortsätta ändå?",
          buttons: [
            {
              label: "Ja",
              onClick: () => this.addCourse(e)
            },
            {
              label: "Nej",
              onClick: null
            }
          ]
        })
      }else if (this.state.desc === "") {
        confirmAlert({
          title: "Utbildningen saknar innehåll",
          message: "Vill du fortsätta ändå?",
          buttons: [
            {
              label: "Ja",
              onClick: () => this.addCourse(e)
            },
            {
              label: "Nej",
              onClick: null
            }
          ]
        })
      }else if (Number(this.state.price_employee) > Number(this.state.price)) {
        confirmAlert({
          title: "Priset för anställd är högre än standardpriset",
          message: "Vill du fortsätta ändå?",
          buttons: [
            {
              label: "Ja",
              onClick: () => this.addCourse(e)
            },
            {
              label: "Nej",
              onClick: null
            }
          ]
        })
      }else {
        this.addCourse(e);
      }
    }else if (missing.length === 1) {
      window.alert("Vänligen fyll i det rödmarkerade fältet.");
    }else {
      window.alert("Vänligen fyll i de rödmarkerade fälten.");
    }
  }


  addCourse = e => {
    //e.preventDefault();
    if (this.state.prefill) {
      this.updateCourse();
    }else {
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      let visitaddress = {address: this.state.visitaddress, postalcode: this.state.postalcode, ort:this.state.ort};
      let webinar = this.state.webinar;
      if (this.state.type === "Webbutbildning") {
        visitaddress = null;
      } else {
        webinar = null;
      }
      db.collection("courses").add({
        title: this.capitalize(this.state.title),
        date: this.state.date,
        starts: this.state.starts,
        ends: this.state.ends,
        desc: this.capitalize(this.state.desc),
        price: Number(this.state.price),
        price_employee: Number(this.state.price_employee),
        webinar: webinar,
        visitaddress: visitaddress,
        platser: Number(this.state.platser),
        type: this.capitalize(this.state.type)
      }).then(window.alert("Klart"));
      this.setState({
        title: "",
        date: 0,
        starts: 0,
        ends: 0,
        desc: "",
        price: 0,
        price_employee: 0,
        webinar: "",
        visitaddress: "",
        postalcode: "",
        ort: "",
        platser: 0
      })
    }
  }

  render () {
    let yesno;
    let buttonText = "LÄGG TILL";
    if (this.state.prefill) {
      buttonText = "SPARA ÄNDRINGAR";
    }
    let addressInput;
    if (!this.state.web) {
      yesno = "Nej";
      let platserTr = (
        <tr>
          <td className="label">Antal platser:</td>
          <td>
            <input name="platser" type="number" onChange={this.updateInput} value={this.state.platser} />
            <span className="suffix">st</span>
          </td>
        </tr>
      );
      let totalAnmalda;
      if (this.state.prefill) {
        platserTr = (
          <tr>
            <td className="label">Platser kvar:</td>
            <td>
              <input name="platser" type="number" onChange={this.updateInput} value={this.state.platser} />
              <span className="suffix">st</span>
              <span> --<i>Ändra här för att lägga till fler platser</i></span>
            </td>
          </tr>
        );
        totalAnmalda = (
          <tr>
            <td className="label">Totalt antal anmälda:</td>
            <td>
              <i>{this.props.prefill.totalAnmalda}</i>
            </td>
          </tr>
        )
      }
      addressInput = (
          <tfoot>
            <tr>
              <td className="label">Besöksadress:</td>
              <td>
                <input name="visitaddress" type="text" onChange={this.updateInput} value={this.state.visitaddress} />
              </td>
            </tr>
            <tr>
              <td className="label">Postnummer:</td>
              <td>
                <input name="postalcode" type="text" onChange={this.updateInput} value={this.state.postalcode} />
              </td>
            </tr>
            <tr>
              <td className="label">Ort:</td>
              <td>
                <input name="ort" type="text" onChange={this.updateInput} value={this.state.ort} />
              </td>
            </tr>
            {totalAnmalda}
            {platserTr}
        </tfoot>
      );
    }else {
      yesno = "Ja";
      addressInput = (
        <tfoot>
          <tr>
            <td className="label">Webinar url:</td>
            <td>
              <input name="webinar" type="text" onChange={this.updateInput} value={this.state.webinar} />
            </td>
          </tr>
        </tfoot>
      );
    }
    let checkType = (
      <tr>
        <td className="label">Är detta en webbutbildning?</td>
        <td>
          <input name="web" type="checkbox" onClick={(e) => {this.updateType(); this.setState({web:e.target.checked})}} value={this.state.web} />
          <span className="suffix">{yesno}</span>
        </td>
      </tr>
    );
    if (this.state.prefill) {
      checkType = (
        <tr>
          <td className="label">Typ:</td>
          <td>
            {this.state.type}
          </td>
        </tr>
      )
    }
    return (
      <div>
        <form className="inputForm" id="courseForm">
          <table>
            <tbody>
              <tr>
                <td className="label">Kursens namn:</td>
                <td>
                  <input name="title" type="text" placeholder=".........." onChange={this.updateInput} value={this.state.title} />
                </td>
              </tr>
              <tr>
                <td className="label">Datum:</td>
                <td>
                  <input name="date" type="date" onChange={this.updateInput} value={this.state.date} />
                </td>
              </tr>
              <tr>
                <td className="label">Kursen börjar:</td>
                <td>
                  <input name="starts" type="time" onChange={this.updateInput} value={this.state.starts} />
                </td>
              </tr>
              <tr>
                <td className="label">Kursens slutar:</td>
                <td>
                  <input name="ends" type="time" onChange={this.updateInput} value={this.state.ends} />
                </td>
              </tr>
              <tr>
                <td className="label">Pris:</td>
                <td>
                  <input name="price" type="number" onChange={this.updateInput} value={this.state.price} />
                  <span className="suffix">kr</span>
                </td>
              </tr>
              <tr>
                <td className="label">Pris för anställd:</td>
                <td>
                  <input name="price_employee" type="number" onChange={this.updateInput} value={this.state.price_employee} />
                  <span className="suffix">kr</span>
                  {(Number(this.state.price_employee) > Number(this.state.price)) && <p style={{color: "#ff2e00", marginBottom: 0}}><i>Bör inte vara högre än standardpriset</i></p>}
                </td>
              </tr>
              {checkType}
            </tbody>
            {addressInput}
          </table>

          <label className="label">
            Innehåll:
            <div style={{height:10}}></div>
            <TextareaAutosize name="desc" type="text" placeholder="Skriv..." onChange={this.updateInput} value={this.state.desc} />
          </label>
          <button className="bigGreenButton" onClick={this.checkInput}>{buttonText}</button>
        </form>
      </div>
    );
  }




}
export default NewCourse;
