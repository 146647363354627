import React, { Component } from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
    this.handleClickLogin = this.handleClickLogin.bind(this);
  }

  handleEmailChange(e) {
    this.setState({email:e.target.value});
  }
  handlePassChange(e) {
    this.setState({password:e.target.value});
  }
  handleClickLogin(e) {
    this.props.handleClick(this.state.email,this.state.password);
  }

  render() {
    return (
      <div className="login-page">
        <div className="form">
          <h1>Adxto Care Admin</h1>
          <form className="login-form">
            <input type="text" placeholder="email" onChange={(e) => this.handleEmailChange(e)}/>
            <input type="password" placeholder="password" onChange={(e) => this.handlePassChange(e)}/>
            <button type="button" onClick={this.handleClickLogin} className="logInButton">login</button>
          </form>
          <p className="message">Copyright Appberg 2018</p>
        </div>
      </div>
    );
  }
}
