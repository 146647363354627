import React, { Component } from 'react';
import './App.css';
import Login from './containers/Login.js';
import Main from './Main.js';
import logo from './media/logo.svg';

const firebase = require('./Firebase.js');

class App extends Component {
  constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.handleLogin = this.handleLogin.bind(this);
  this.state = {
    loggedIn:0
  }

}
componentDidMount() {
  this.checkLogin();
}
checkLogin() {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      this.setState({loggedIn: 2});
      firebase.user = user;
      firebase.uid = user.uid;
    }
    else {
      this.setState({ loggedIn: 1 });
    }
  }.bind(this));

}
handleLogin(email, pass) {
  this.setState({loggedIn: 0});
  firebase.auth().signInWithEmailAndPassword(email, pass).catch(function(error) {
    //var errorCode = error.code;
    var errorMessage = error.message;
    window.alert(errorMessage);
    this.checkLogin();
  }.bind(this));

}
  render() {
    let loggedIn = this.state.loggedIn;
    if (loggedIn === 2) {
      return (
        <Main />
      );
    } else if (loggedIn === 1) {
      return(
        <div className="App">
          <Login handleClick={this.handleLogin} />
        </div>
      );
    }else {
      return(
        <div className="App">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      );
    }
  }
}

export default App;
