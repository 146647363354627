const firebase = require('firebase');

const config = {
    apiKey: "AIzaSyBQ7YehlZq_X7g_PZB8w9zGBJrJfkU4MZM",
    authDomain: "adxtocare-3a3fe.firebaseapp.com",
    databaseURL: "https://adxtocare-3a3fe.firebaseio.com",
    projectId: "adxtocare-3a3fe",
    storageBucket: "adxtocare-3a3fe.appspot.com",
    messagingSenderId: "799060317819"
  };

firebase.initializeApp(config);

firebase.initialLoad = function load(user) {
  firebase.user = user;
  firebase.uid = user.uid;
  console.log("USER ID " + user.uid + " USER: " + user);
}

firebase.getArray = function snapshotToArray(snapshot) {
    const returnArr = [];
    if (snapshot) {
      snapshot.forEach(function(childSnapshot) {
          const item = childSnapshot.data();
          item.id = childSnapshot.id;

          returnArr.push(item);
      });
    }

    return returnArr;
};

firebase.arrayFromObject = function arrayFromObject(object) {
  let returnArr = [];
  if (object) {
    Object.keys(object).forEach(key => {
      let newObject = object[key];
      newObject.id = key;
      returnArr.push(newObject);
    });
  }
  return returnArr;
}

firebase.timeConvert = function timeConverter(UNIX_timestamp){
  if (UNIX_timestamp) {
    const a = new Date(UNIX_timestamp.seconds * 1000);
    const text = a.toLocaleDateString("se");
    return text;
  } else {
    return null;
  }
}

module.exports = firebase;

/*import firebase from 'firebase';

const config = {
   apiKey: "AIzfhrwkKhDBFHbntnKhTahHjkNK6FG875dSB6y",
   authDomain: "typecode-76g33.firebaseapp.com",
   databaseURL: "https://typecode-76g33.firebaseio.com",
   projectId: "typecode-76g33",
   storageBucket: "",
   messagingSenderId: "547432956432"
};
firebase.initializeApp(config);

export default firebase;
*/
