import React, {Component} from 'react';
import PageTitle from './PageTitle.js';

class Help extends Component {

  render () {
    return (
      <div>
        <PageTitle title="Hjälp" />
      </div>
    );
  }
}
export default Help;
