import React, {Component} from 'react';
import PageTitle from './PageTitle.js';
import NewCourse from '../containers/NewCourse.js';

class AddCourse extends Component {

  render() {
    return (
      <div>
        <PageTitle title="Lägg till utbildning" />
        <div className="section">
          <NewCourse />
        </div>
      </div>
    );
  }
}
export default AddCourse;
