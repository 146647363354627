import React, {Component} from 'react';
import PageTitle from './PageTitle.js';
import ReactTable from "react-table";
import 'react-table/react-table.css'
//import Upcoming from '../containers/Upcoming.js';
import OpenCourse from './OpenCourse.js';
import * as sort from '../containers/SortCourse.js';
//import { BeatLoader } from 'react-spinners';
//import Popup from 'react-popup';
//import './Popup.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const firebase = require('../Firebase.js');



class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      open: 0,
      filters: [],
      type: "all",
      time: "all",
      oldAndUpc: false
    }
  }


  componentDidMount() {
    const db = firebase.firestore();
    //const settings = {/* your settings... */ timestampsInSnapshots: true};
    //db.settings(settings);*/
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection('courses').onSnapshot((snapshot) => {
      const courses = firebase.getArray(snapshot);
      this.setState({ data: courses });
        /*snapshot.forEach((doc) => {
          console.log(doc.id, "=>", doc.data());
          this.setState({ data: firebase.getArray(snapshot), key: doc.id });
        });*/
    });
  }

  applyFilter = e => {
    const val = e.toString();

    if (this.state.filters.includes(val)) {
      const arr = this.state.filters;
      const index = arr.indexOf(val);
      if (arr.length > 1) {
        const newArr = arr.splice(index-1, 1);
        this.setState({filters: newArr});
      }else {
        this.setState({filters:[]});
      }
    }
    else {
      const filters = this.state.filters.slice();
      filters.push(val);
      this.setState({filters: filters});
    }
  }

  applyTime = e => {
    const time = this.state.time;
    const oldAndUpc = this.state.oldAndUpc; // = false

    if (time === "all" && !oldAndUpc) {
      this.setState({time: e})
    }
    else if (time === "all" && oldAndUpc) {
      if (e === "upc") {
        this.setState({time: "old", oldAndUpc: false});
      }else if (e === "old") {
        this.setState({time: "upc", oldAndUpc: false});
      }
    }
    else if (time !== e) {
      if (time === "upc" || time === "old") {
        this.setState({time: "all", oldAndUpc: true});
      }
    }
    else if (time === e) {
      this.setState({time: "all", oldAndUpc: false});
    }

  }

  toggleOpen = e => {
    if (e !== 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    this.setState({open: e});
  }



  render() {
    let data = [];
    const filters = this.state.filters;
    const time = this.state.time;
    const oldAndUpc = this.state.oldAndUpc;
    if (filters.length > 0) {
        filters.forEach(function(filter) {
          if (this.state.time === "old") {
            const forFilter = sort.sortFunction(this.state.data, filter, "old");
            data = data.concat(forFilter);
          }else if (this.state.time === "upc") {
            const forFilter = sort.sortFunction(this.state.data, filter, "upc");
            data = data.concat(forFilter);
          }else {
            const forFilterUpc = sort.sortFunction(this.state.data, filter, "upc");
            const forFilterOld = sort.sortFunction(this.state.data, filter, "old");
            data = data.concat(forFilterUpc);
            data = data.concat(forFilterOld);
          }

        }, this)
    }else if (time === "old" || time === "upc" || oldAndUpc === true){
      if (time === "old") {
        const forFilterWeb = sort.sortFunction(this.state.data, "web", "old");
        const forFilterFys = sort.sortFunction(this.state.data, "fys", "old");
        data = data.concat(forFilterWeb);
        data = data.concat(forFilterFys);
      } else if (time === "upc"){
        const forFilterWeb = sort.sortFunction(this.state.data, "web", "upc");
        const forFilterFys = sort.sortFunction(this.state.data, "fys", "upc");
        data = data.concat(forFilterWeb);
        data = data.concat(forFilterFys);
      } else {
        data = this.state.data;
      }
    }else {
      data = this.state.data;
    }
    if (this.state.time === "upc") {
      data.sort(function(a, b) {
        a = new Date(a.date);
        b = new Date(b.date);
        return a>b ? 1 : a<b ? -1 : 0;
      });
    }
    else {
      data.sort(function(a, b) {
        a = new Date(a.date);
        b = new Date(b.date);
        return a>b ? -1 : a<b ? 1 : 0;
      });
    }

    if (this.state.open !== 0) {
      return <OpenCourse toggleOpen={this.toggleOpen} openId={this.state.open.id} data={this.state.open}/>
    }else {
      return (
        <div>
          <PageTitle title="Utbildningar" />
          <div className="section clearfix">
            <div className="fullWidth">
              <div className="inputForm">
                <p><b>Visa endast:</b></p>
                <div className="checkboxWrapper">
                  <span>Webbutbildningar <input className="inputCheck" type="checkbox" value="web" onClick={(e) => this.applyFilter(e.target.value)}/></span>
                  <span>Fysiska utbildningar <input className="inputCheck" type="checkbox" value="fys" onClick={(e) => this.applyFilter(e.target.value)}/></span>
                  <span>Kommande <input className="inputCheck" type="checkbox" value="upc" onClick={(e) => this.applyTime(e.target.value)}/></span>
                  <span>Passerade <input className="inputCheck" type="checkbox" value="old" onClick={(e) => this.applyTime(e.target.value)}/></span>
                </div>

              </div>

            </div>
            <div className="fullWidth">
              <CourseList data={data} filter={() => this.applyFilter} toggleOpen={this.toggleOpen} />
            </div>

          </div>
        </div>
      );
    }
  }
}

class CourseList extends Component {

  deleteCourse = e => {
    const db = firebase.firestore();
    //const settings = {/* your settings... */ timestampsInSnapshots: true};
    //db.settings(settings);*/
    db.settings({
      timestampsInSnapshots: true
    });
    function confDelete() {
      db.collection("courses").doc(e).delete();
      console.log("Successfully deleted course");
    }
    confirmAlert({
        title: 'Kursen kommer raderas',
        message:'Vill du fortsätta?',
        buttons: [
          {
            label: 'Ja',
            onClick:() => confDelete()
          },
          {
            label: 'Nej',
            onClick:null
          }
        ]
      });

  }


  render() {
    const columns = [{
        Header: 'Namn',
        accessor: 'title' // String-based value accessors!
      },
      {
        Header: 'Datum',
        accessor: 'date' // Custom cell components!
      }
    ];

    return (
      <ReactTable
        data={this.props.data}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
        columns={columns}
        className="-striped -highlight"
        SubComponent={row => {
          const course = row.original;
          const anmaldaArray = firebase.arrayFromObject(course.anmalda);
          const pendingArray = firebase.arrayFromObject(course.pending);
          return (
            <div style={{padding:10, width:"90%"}}>
              <p><b>Typ: </b>{course.type}</p>
              <p><b>Anmälda, betalda: </b>{anmaldaArray.length}</p>
              <p><b>Anmälda, ej betalde: </b>{pendingArray.length}</p>
              <button className="closeButton" style={{float:'right'}} onClick={() => this.deleteCourse(course.id)}>Ta bort</button>
              <button className="smallBlueButton" style={{float:'right'}} onClick={() => this.props.toggleOpen(course)}>Öppna</button>
            </div>
          )
        }}
    />)
  }
}
export default Courses;
