export function sortFunction(courses, type, time) {
  type = type || "all";
  time = time || "all";
  //courses = (courses ||
  let returnArr = [];
  const today = Date.parse(new Date());
  if (time === "old") {
    courses.forEach((course) => {
      const courseDate = Date.parse(course.date);
      //console.log("DATE: " + cDate);
      //console.log("PARSE: " + courseDate);
      if (today > courseDate) {
        if (type === "web") {
            if (course.type === "Webbutbildning") {
              returnArr.push(course);
            }
        } else if (type === "fys") {
            if (course.type === "Fysisk utbildning") {
              returnArr.push(course);
            }
        } else if (type === "all") {
            returnArr.push(course);
        } else {
            returnArr.push(course);
        }
      }
    });
  } else if (time === "upc") {
    courses.forEach((course) => {
      const courseDate = Date.parse(course.date);
      if (today <= courseDate) {
        if (type === "web") {
            if (course.type === "Webbutbildning") {
              returnArr.push(course);
            }
        } else if (type === "fys") {
            if (course.type === "Fysisk utbildning") {
              returnArr.push(course);
            }
        } else if (type === "all") {
            returnArr.push(course);
        } else {
            returnArr.push(course);
        }
      }
    });
  } else if (time === "all") {
    courses.forEach((course) => {
      if (type === "web") {
          if (course.type === "Webbutbildning") {
            returnArr.push(course);
          }
      } else if (type === "fys") {
          if (course.type === "Fysisk utbildning") {
            returnArr.push(course);
          }
      } else if (type === "all") {
          returnArr.push(course);
      } else {
          returnArr.push(course);
      }
    });
  }

  returnArr.sort(function(a, b) {
    a = new Date(a.date);
    b = new Date(b.date);
    return a<b ? -1 : a>b ? 1 : 0;
  });

  return returnArr;
}
