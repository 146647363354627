//menu
import React, { Component } from 'react';
import './Menu.css';

class Menu extends Component {

  render() {
    let m1 = "";
    let m2 = "";
    let m3 = "";
    let m4 = "";
    let m5 = "";
    const i = this.props.i;
    const loggedIn = this.props.loggedIn;

    if (i === 0) {
      m1 = "activeLi";
    }else if (i === 1) {
      m2 = "activeLi";
    }else if (i === 2) {
      m3 = "activeLi";
    }else if (i === 3) {
      m4 = "activeLi";
    }else if (i === 4) {
      m5 = "activeLi";
    }else {
      m1 = "activeLi";
    }

    if (loggedIn) {
      return (
        <ul className="mainMenu">

          <li><a className={m2} onClick={(e) => this.props.change(1)} href="#">Användare</a></li>
          <li><a className={m3} onClick={(e) => this.props.change(2)} href="#">Utbildningar</a></li>
          <li><a className={m4} onClick={(e) => this.props.change(3)} href="#">Lägg till utbildning</a></li>
          <li><a className={m5} onClick={(e) => this.props.change(4)} href="#">Hjälp</a></li>
        </ul>
      );
    } else {
      return (
        <ul className="mainMenu">
          <li><a className={m1} onClick={(e) => this.props.change(0)} href="#">Om utbildningarna</a></li>
          <li><a className={m2} onClick={(e) => this.props.change(1)} href="#">Utbildningar</a></li>
          {/*<li><a className={m3} onClick={(e) => this.props.change(2)} href="#">Webbutbildningar</a></li>*/}
          <li><a className={m4} onClick={(e) => this.props.change(3)} href="#">Hjälp</a></li>
        </ul>
      );
    }
  }
}

export default Menu;
