import React from 'react';

/**class PageTitle extends Component {
  render () {
    const title = this.props.title;
    return (
      <div className="titleContainer clearfix">
        <h1 className="headerTitle">{title}</h1>
        <hr className="headerLine"></hr>
      </div>
    );
  }
}**/
const PageTitle = (props) => (
  <div className="titleContainer clearfix">
    <h1 className="headerTitle">{props.title}</h1>
    <hr className="headerLine"></hr>
  </div>
);
export default PageTitle;
