import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
import './Main.css';
import Menu from './containers/Menu.js';
import LogOutButton from './containers/Log-out-button.js';
import './Divs.css';
import './containers/form.css';

//VIEWS
//import MyPage from './views/MyPage.js';
import Courses from './views/Courses.js';
import AddCourse from './views/AddCourse.js';
import WebCourses from './views/WebCourses.js';
import HandleUsers from './views/HandleUsers.js';
import EditTexts from './views/EditTexts.js';
import Help from './views/Help.js';
import './views/Views.css';
const firebase = require("./Firebase.js");

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItem:1,
      loggedIn:true
    }
  }
  changeMenuItem(i) {
    this.setState({menuItem:i});
  }
  changeLoggedIn(e) {
    firebase.auth().signOut();
  }
  render() {
    const user = firebase.auth().currentUser;
    const i = this.state.menuItem;
    let view;
    if (this.state.loggedIn) {
      if (i === 0) {
        view = (<HandleUsers />);
      }else if (i === 1) {
        view = (<HandleUsers />);
      }else if (i === 2) {
        view = (<Courses />);
      }else if (i === 3) {
        view = (<AddCourse />);
      }else if (i === 4) {
        view = (<Help />);
      }
    }else {
      if (i === 0) {
         view = (<HandleUsers />);
       }else if (i === 1) {
         view = (<Courses />);
       }else if (i === 2) {
         view = (<WebCourses />);
       }else if (i === 3) {
         view = (<Help />);
       }
    }


    return (
      <div className="Outer">
        <header className="headerWrapper">
          <div className="App-header" style={{zIndex:100}}>
            <div style={{width:"30%",height:"100%",margin:0,float:"left"}}>
              <img style={{maxHeight:"100px", maxWidth: "200px"}} alt="Logga Adxto Care" src={require('./media/adxto.care.logo.png')} />
            </div>
            <div style={{width:"40%",margin:0,float:"left",textAlign:"center"}}>
              <h1 style={{width:"100%"}} className="App-title">Adxto Care Utbildningar</h1>
            </div>
            <div style={{width:"30%",height:"100%",margin:0,float:"right"}}>
              <LogOutButton loggedIn={this.state.loggedIn} change={this.changeLoggedIn.bind(this)} />
              <h3 style={{float:"right"}}>{user.email}</h3>
            </div>
          </div>
        </header>
        <div id="main">
          <div id="nav">
            <Menu loggedIn={this.state.loggedIn} i={i} change={this.changeMenuItem.bind(this)} />

          </div>
          <div id="view">
            <div id="inner">{view}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
